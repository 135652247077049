import api from "../api";
export const FogotPasswordNewPass = async (email,code,password) => {
  try {
    const formatData = {
        email:email,
        password:password,
        token:code
      };
    //console.log(formData,"ss");
    const response = await api.post("/api/reqnewpassconfirm",formatData);
    //alert("IMPORT DONE");
    //console.log(response.data.message,"ss");
    return response.data.message;
  } catch (error) {
   console.error(error);
  }
};
