import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { useUserContext } from "./UserContext";

const Layout = ({ children }) => {
  const { userRole, logout,updateUserRole } = useUserContext();
  const [roleValidated, setRoleValidated] = useState(false);

  useEffect(() => {
    if (!userRole) {
      const storedUserRole = localStorage.getItem("userRole");
      if (storedUserRole) {
        
        updateUserRole(storedUserRole);
      //  console.log(storedUserRole,"Stored ROLE");
        setRoleValidated(true)
      }else{
       // console.log(window.location.pathname);
        if (!window.location.pathname.includes("/forgotnewpass") ) {
          //navigate("/");
         window.location.href = "/";

        }
     // console.log(roleValidated);
    }
    } else {
      setRoleValidated(true);
    }
        // eslint-disable-next-line
  }, [userRole, logout]);


    
  if (!roleValidated && !window.location.pathname.includes("/forgotnewpass")) {
    // console.log(circleColor,departmentColor,categoryColor,productColor);
     return <div>Loading...</div>;
   }

  return (
    <Container>
      <Sidebar />
      <MainContent>
        <Logo>
          <img
            src="../image 1.png"
            alt="Logo"
            width="200px"
            max-width="300px"
            position="absolute"
            right="35px"
            top="40px"
          />
        </Logo>
        {children}
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 990px) {
    height: 120vh !important;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 15px;
`;

const Logo = styled.div`
  flex-shrink: 0;
  position: absolute;
  padding: 20px;
  right: 35px;
  top: 20px;
`;

export default Layout;
