import api from "../api";
export const AddProductsToDB = async (formDataWithFiles) => {

  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const response = await api.post("/api/addproducts", formDataWithFiles);
   // console.log(response.data);
    alert(response.data.message);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
