import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";
import AddCategories from "../AddPages/AddCategories";
import { fetchCategories } from "../FetchData/FetchCategories";
import { DeleteCategory } from "../DeleteFromDB/DeleteCategory";
import EditCategoryModal from "../EditPages/EditCategoryModal";
import { EditCategoryToDB } from "../EditToDB/EditCategoryToDB";
import "../globalstyle.css";

const Categories = () => {
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [categoryChangeFlag, setCategoryChangeFlag] = useState(true);


  //For Pagination and Search

  const [locations, setLocations] = useState([]); // Maintain state for locations
  const [selectedLocation, setSelectedLocation] = useState("All"); // Selected location filter
  const [departments, setDepartments] = useState([]); // Maintain state for departments
  const [selectedDepartment, setSelectedDepartment] = useState("All"); // Selected department filter
  const [pageRange, setPageRange] = useState({ start: 0, end: 4 });
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setpaginatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Maintain a copy of filtered data
  const handleLocationFilterChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  // Handle department filter change
  const handleDepartmentFilterChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

let data = { nodes: paginatedData };
const [search, setSearch] = React.useState('');
const handleSearchName = (event) => {
  setSearch(event.target.value);
  setItemsPerPage(categories.length);
  const filteredData = paginatedData.filter((item) =>
    item.name.toLowerCase().includes(event.target.value.toLowerCase())
  );
 // console.log(data.nodes);
};
data = {
  nodes: data.nodes.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  ),
};
const handleItemsPerPageChange = (value) => {
  setItemsPerPage(value);
  setCurrentPage(0); 
};
//USE EFFECTS
//UseEffect to Map Unique Locations and Departments into DropDowns 
useEffect(() => {
  const uniqueLocations = Array.from(
    new Set(categories.map((item) => item.location.store_name))
  );
  const uniqueDepartments = Array.from(
    new Set(categories.map((item) => item.department.name))
  );

  setLocations(["All", ...uniqueLocations]);
  setDepartments(["All", ...uniqueDepartments]);
}, [categories]);
//-------------------------------------------------------
//useEffect for when If any new category added we get that 
useEffect(() => {
  async function fetchCategoriesData() {
    try {
      const data = await fetchCategories();
      setCategories(data);
     // console.log(data);
      setCategoryChangeFlag(false);
    } catch (error) {
      console.error(error);
    }
  }
  if (categoryChangeFlag) {
    fetchCategoriesData();
  }
}, [categoryChangeFlag]);

//-----------------------------------------
//useEffect to updateData on location or department Select
useEffect(() => {
  let filteredData = categories;
  if (selectedLocation !== 'All') {
    filteredData = filteredData.filter((item) => item.location.store_name === selectedLocation);
  }
  if (selectedDepartment !== 'All') {
    filteredData = filteredData.filter((item) => item.department.name === selectedDepartment);
  }
  setFilteredData(filteredData);
}, [selectedLocation, selectedDepartment, categories]);

//-------------------------------------------------
//useEffect to Handle Pagination
useEffect(() => {
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  setpaginatedData(paginatedData);
    const maxPage = Math.max(1, Math.ceil(filteredData.length / itemsPerPage));  
  setCurrentPage((prevPage) => {
    if (prevPage < 0) return 0;
    if (prevPage >= maxPage) return maxPage - 1;
    return prevPage;
  });
  const maxVisiblePageButtons = 5;
    const newStart = Math.min(
    Math.max(0, currentPage - Math.floor(maxVisiblePageButtons / 2)),
    Math.max(0, maxPage - maxVisiblePageButtons)
  );
  const newEnd = Math.min(newStart + maxVisiblePageButtons, maxPage);
  setPageRange({ start: newStart, end: newEnd-1 });
}, [currentPage, itemsPerPage, filteredData]);

// USE EFFECTS

///////////FOR PAGINATION START

const handleJumpToPage = (page) => {
  setCurrentPage(page);
};


  const toggleAddCategoriesForm = () => {
    setShowAddCategoryForm(!showAddCategoryForm);
  };

  function onSortChange(action, state) {
   // console.log(action, state);
  }

  const theme = useTheme({
    HeaderRow: `
          background-color: #F2F2F2;
        `,
    Row: `
          &:nth-of-type(odd) {
            background-color: #FFFFF;
          }
  
          &:nth-of-type(even) {
            background-color: #F2F2F2;
          }
        `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
          DEPT_ID: (array) =>
          array.sort((a, b) => a.id - b.id),
        DEPT_NAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        DEPT_DESC: (array) =>
          array.sort((a, b) => {
            const descriptionA = (a.description || "").toString();
            const descriptionB = (b.description || "").toString();
            return descriptionA.localeCompare(descriptionB);
          }),
        DEPT_DATE: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
        DEPT_LOC_NAME: (array) =>
          array.sort((a, b) => a.location_id.localeCompare(b.location_id)),
        DEPT_LOC_ID: (array) =>
          array.sort((a, b) => a.location_id - b.location_id),
      },
    }
  );
  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };
  
  const confirmDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Category? This Will Delete All Products linked with this Category..?"
      )
    ) {
      handleDelete(id);
    }
  };
  const handleDelete = async (id) => {
    try {
      await DeleteCategory(id).then(() => {
        setCategories(categories.filter((category) => category.id !== id));
      });
    } catch (error) {
      alert("Something Went Wrong in Delete ");
      console.error(`Error deleting Category with ID ${id}:`, error);
    }
  };

  function handleEdit(categoryData) {
    setEditCategoryData(categoryData);
    setShowEditModal(true);
  }

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditCategoryData({});
  };

  const handleSaveEdit = async (updatedData) => {
    try {
      await EditCategoryToDB(updatedData);
      setCategoryChangeFlag(!categoryChangeFlag);
    } catch (error) {
      console.error(
        `Error updating Category with ID ${updatedData.id}:`,
        error
      );
      alert("Something Went Wrong in Edit ");
    }
  };

  return (
    <div>
      {showAddCategoryForm ? (
        <AddCategories
          setCategoryChangeFlag={setCategoryChangeFlag}
          toggleAddCategoriesForm={toggleAddCategoriesForm}
        />
      ) : (
        <>
          <div className="text-parent">
            <h2 className="welcomeText">Categories</h2>
            <button className="Catadd-button" onClick={toggleAddCategoriesForm}>
              {" "}
              + Add New
            </button>
          </div>

      {/* ... Your existing code ... */}
      {/* Location filter dropdown */}
      <div className="mySearch" style={{ display: "flex" }}>


      <label htmlFor="search">
    Search :
    <input id="search" type="text"  onChange={handleSearchName}/>
  </label>
      <div className="Catlocation-filter" style={{ 
        marginLeft:"5px",minWidth:"300px"
    }}>
        <label htmlFor="location-filter">Filter by Location: </label>
        <select
          id="location-filter"
          value={selectedLocation}
          onChange={handleLocationFilterChange}
        >
          {locations.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>
      <div className="Catdepartment-filter" style={{ 
         marginLeft:"5px",minWidth:"300px"}}>
        <label htmlFor="department-filter">Filter by Department: </label>
        <select
          id="department-filter"
          value={selectedDepartment}
          onChange={handleDepartmentFilterChange}
        >
          {departments.map((department) => (
            <option key={department} value={department}>
              {department}
            </option>
          ))}
        </select>
      </div>
      </div>

          <div className="CatmyTable" style={{ marginTop: "135px" }}>
            <Table data={data} sort={sort} theme={theme}>
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_ID"
                      >
                        ID
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_NAME"
                      >
                        Name
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DESC"
                      >
                        Description
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_NAME"
                      >
                        Location
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_ID"
                      >
                        Department
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DATE"
                      >
                        Date Added
                      </HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">Edit</HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">
                        Delete
                      </HeaderCellSort>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item) => (
                      <Row item={item} key={item.id}>
                        <Cell>{item.id}</Cell>
                        <Cell>{item.name}</Cell>
                        <Cell>{item.description}</Cell>
                        <Cell>{item.location.store_name}</Cell>
                        <Cell>{item.department.name}</Cell>
                        <Cell>
                          {item.created_at
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </Cell>
                        <Cell>
                          <IconButton
                            color="primary"
                            aria-label="Edit"
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Cell>
                        <Cell>
                          <IconButton
                            color="secondary"
                            aria-label="Delete"
                            onClick={() => confirmDelete(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </div>

          
{/* //For Pagination And Search */}
<div className="myPagination" style={{display:"flex"}}>
<div className="pagination-controls" style={{marginRight:"10px"}}> 
  <button
    onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
    disabled={currentPage === 0}
  >
    Previous
  </button>
  <button
    onClick={() =>
      setCurrentPage(Math.min(currentPage + 1, Math.ceil(categories.length / itemsPerPage) - 1))
    }
    disabled={currentPage === Math.ceil(categories.length / itemsPerPage) - 1}
  >
    Next
  </button>
</div>

     {/* Page navigation */}
     {Math.ceil(categories.length / itemsPerPage) > 1 && (
              <div>
                {Array.from({ length: pageRange.end - pageRange.start + 1 }).map(
                  (_, index) => {
                    const page = pageRange.start + index;
                    return (
                      <button
                        key={page}
                        onClick={() => handleJumpToPage(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                )}
              </div>
            )}


<select
style={{marginLeft: "5px"}}
  value={itemsPerPage}
  onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}
>
<option value={1}>1 per page</option>
  <option value={10}>10 per page</option>
  <option value={20}>20 per page</option>
  <option value={50}>50 per page</option>
  <option value={categories.length}>All</option>

</select>

<div style={{marginLeft:"5px"}}>
    Total Records: {categories.length}
  </div>

</div>

          <EditCategoryModal
            isOpen={showEditModal}
            onRequestClose={closeEditModal}
            CategoryData={editCategoryData}
            onSave={handleSaveEdit}
          />
        </>
      )}
    </div>
  );
};

export default Categories;
