import api from "../api";

export const EditLocationToDB = async (updatedData) => {
  try {
    const jsonData = JSON.stringify(updatedData);

    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "application/json"; // Add this line to set the Content-Type header

    //console.log(jsonData, "the data for send");

    const response = await api.put(`/api/locations/${updatedData.id}`, jsonData);

    // Handle the response directly
    if (response.status === 200) {
      alert("Edit Done !");
    } else {
      // Handle other status codes if needed
      console.warn("Unexpected response status:", response.status);
    }

    return response.data; // Return the response data if needed
  } catch (error) {
    console.error("An error occurred:", error);

    // Handle the error, show an alert, or log it
    // For example: alert("Error editing location.");

    // Re-throw the error to propagate it further if needed
    throw error;
  }
};
