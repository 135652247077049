import api from "../api";
export const FogotPasswordReq = async (email) => {
  try {
    const formatData = {
        email:email,
      };
    //console.log(formData,"ss");
    const response = await api.post("/api/reqnewpass",formatData);
    //alert("IMPORT DONE");
    //console.log(response.data.message,"ss");
    return response.data.message;
  } catch (error) {
   console.error(error);
  }
};
