import React from "react";
import "./globalstyle.css";

const ViewLocation = (props) => {
  const { toggleViewLocationForm, selectedLocation } = props;
// console.log("IN VIEW");
//  console.log(selectedLocation);
 const formatTimeToAMPM = (time) => {
    const [hours, minutes] = time.split(":");
    let period = "AM";
    let formattedHours = parseInt(hours, 10);
  
    if (formattedHours >= 12) {
      period = "PM";
      if (formattedHours > 12) {
        formattedHours -= 12;

      }
    } else if (formattedHours === 0) {
      formattedHours = 12;
    }
  
    const formattedMinutes = minutes.padStart(2, "0");
    const formattedHoursString = formattedHours.toString().padStart(2, "0");

  
    // Return an object with hours, minutes, and period
    return {
      hours: formattedHoursString,
      minutes: formattedMinutes,
      period: period,
    };
  };
  return (
    <>
      <div
        className="view-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleViewLocationForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">View Location</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormView"
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            padding: "20px",
            width: "100%",
          }}
        >

          <h2 style={{ marginLeft: "30px" }}>Location Details</h2>

          {/* Include other product details here */}
          {selectedLocation && (
            <>
              <div style={{ marginLeft: "30px" }}>
                Store Name: {selectedLocation.store_name}
              </div>
              <div style={{ marginLeft: "30px" }}>
             Retail Code: {selectedLocation.retail_code || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
              Address: {selectedLocation.address || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
              State: {selectedLocation.state || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Country: {selectedLocation.country || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>Zip Code: {selectedLocation.zip_code || '.......'}</div>
              <div style={{ marginLeft: "30px" }}>
              Location Manager Name: {selectedLocation.location_manager_name || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>Location Manager Phone: {selectedLocation.location_manager_phone || '.......'}</div>
              <div style={{ marginLeft: "30px" }}>Area Manager Name: {selectedLocation.area_manager_name || '.......'}</div>
              <div style={{ marginLeft: "30px" }}>Area Manager Phone: {selectedLocation.area_manager_phone || '.......'}</div>
<div style={{ marginLeft: "30px" }}>Monday to Friday Time: {formatTimeToAMPM(selectedLocation.mon_to_fri_opening).hours+":"+formatTimeToAMPM(selectedLocation.mon_to_fri_opening).minutes+" "+formatTimeToAMPM(selectedLocation.mon_to_fri_opening).period+" TO " +formatTimeToAMPM(selectedLocation.mon_to_fri_closing).hours+":"+formatTimeToAMPM(selectedLocation.mon_to_fri_closing).minutes+" "+ formatTimeToAMPM(selectedLocation.mon_to_fri_closing).period || '.......'}</div>
<div style={{ marginLeft: "30px" }}>Saturday to Sunday Time:  {formatTimeToAMPM(selectedLocation.sat_to_sun_opening).hours+":"+formatTimeToAMPM(selectedLocation.sat_to_sun_opening).minutes+" "+formatTimeToAMPM(selectedLocation.sat_to_sun_opening).period+" TO " +formatTimeToAMPM(selectedLocation.sat_to_sun_closing).hours+":"+formatTimeToAMPM(selectedLocation.sat_to_sun_closing).minutes+" "+ formatTimeToAMPM(selectedLocation.sat_to_sun_closing).period || '.......'}</div>

            </>
          )}

          <div className="file-upload" style={{ marginLeft: "30px" }}>
            <h2>Documents</h2>
            {/* Display uploaded documents */}
            {selectedLocation && selectedLocation.documents && selectedLocation.documents.length > 0 ? (
  selectedLocation.documents.map((file, index) => (
    <div key={file.id}>{file.name}</div>
  ))
) : (
  <div>No documents</div>
)}

          </div>

        </form>
      </div>
    </>
  );
};

export default ViewLocation;
