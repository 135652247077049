import api from "../api";
export const AddDepartmentsToDB = async (formData) => {
  try {
    const formatData = {
      location_id: formData.location_id,
      name: formData.dept_name,
      description: formData.dept_desc,
    };
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api.post("/api/adddepartment", formatData);
    alert(response.data.message);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
