import api from "../api";
export const ImportProductsToDB = async (formData,locid,depid,catid) => {
  try {
    //console.log(formData,"ss");
    const myformData = new FormData();
      myformData.append(`files[0]`, formData);
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const response = await api.post("/api/importproducts/"+locid+"/"+depid+"/"+catid, myformData);
    alert("IMPORT DONE");
    //console.log(response.data.message,"ss");
    return response;
  } catch (error) {
   // console.error(error);
    if(error.response.data.message){
        const errorm =  error.response.data.message;
        console.error(errorm);
        const duplicateEntryMatch = errorm.match(/Duplicate entry '([^']+)'/);
        if (duplicateEntryMatch) {
          const duplicateEntryValue = duplicateEntryMatch[1];
          alert(`Duplicate entry: ${duplicateEntryValue}`);
        }
    }
  }
};
