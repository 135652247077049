import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "60%",
    minHeight: "350px",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditDepartmentModal = ({
  isOpen,
  onRequestClose,
  DepartmentData,
  onSave,
}) => {
  const initialFormData = {
    id: "",
    name: "",
    description: "",
    location_id: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (DepartmentData) {
      // console.log(DepartmentData.name);
      setFormData({
        id: DepartmentData.id,
        name: DepartmentData.name || "",
        description: DepartmentData.description || "",
        location_id: DepartmentData.location_id || "",
      });
      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        setLocations(locdata);
        // console.log(locations);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [DepartmentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSave(formData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Location"
    >
      <div style={{ textAlign: "center" }}>
        <h2>Edit Department</h2>
        <form className="myFormEdit" onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
              required
            />
          </label>
          <label style={{ marginLeft: "15px" }}>
            Description:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="description"
              value={formData.description || ""}
              onChange={handleChange}
            />
          </label>
          <br></br>
          <label>
            Location:
            <select
              style={{ marginLeft: "15px" }}
              name="location_id"
              value={formData.location_id || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.store_name}
                </option>
              ))}
            </select>
          </label>
          <br></br>
          <button style={{ marginTop: "10px" }} type="submit">
            Update
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default EditDepartmentModal;
