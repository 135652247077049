import React from "react";
import Modal from "react-modal";

const customStyles = {
    content: {
      top: "60%",
      left: "50%",
      right: "auto",
      width: "60%",
      minHeight: "350px",
      bottom: "auto",
      marginRight: "-50%",
      zIndex: 9999,
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

Modal.setAppElement("#root");
const ViewPermissions = ({userData, isOpen, onRequestClose }) => {

        const uniqueLocations = Array.from(
            new Set(userData.department_permissions.map((permission) => permission.department.location.id))
          );




  return (

    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel="View Permissions"
  >

    <div style={{overflow:"auto"}}>
      <h3>User Permissions</h3>

      {userData.department_permissions.length === 0 ? ( // Check if there are no permissions
          <p>No Permissions</p>
        ) : (
            uniqueLocations.map((locationId) => (
        <div key={locationId}>
          <h3>{userData.department_permissions.find((permission) => permission.department.location.id === locationId).department.location.store_name}</h3>
          <ul>
            {userData.department_permissions
              .filter((permission) => permission.department.location.id === locationId)
              .map((permission) => (
                <li key={permission.id}> {permission.department.name}</li>
              ))}
          </ul>
        </div>
      ))
        )}
    </div>
    </Modal>
  );
}

export default ViewPermissions;
