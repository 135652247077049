import React from "react";
import "./globalstyle.css";
import { product_image_address } from "./staticValues";

const ViewProduct = (props) => {
  const { toggleViewProductForm, selectedProduct } = props;
// console.log("IN VIEW");
// console.log(selectedProduct);
  return (
    <>
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleViewProductForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Product</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            padding: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ marginLeft: "30px" }}>Product Location</h2>
          {/* Include location, department, and category details here */}
          {selectedProduct && (
            <>
              <div style={{ marginLeft: "30px" }}>
                Location: {selectedProduct.location.store_name}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Department: {selectedProduct.department.name}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Category: {selectedProduct.category.name}
              </div>
            </>
          )}

          <h2 style={{ marginLeft: "30px" }}>Product Details</h2>

          {/* Include other product details here */}
          {selectedProduct && (
            <>
              <div style={{ marginLeft: "30px" }}>
                Product Name: {selectedProduct.name}
              </div>
              <div style={{ marginLeft: "30px" }}>
              Assigned To: {selectedProduct.assignedto || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Vendor Name: {selectedProduct.vendorname || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Date Bought: {selectedProduct.date_bought || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>
                Vendor Phone: {selectedProduct.vendorphone || '.......'}
              </div>
              <div style={{ marginLeft: "30px" }}>Tags #: {selectedProduct.tags || '.......'}</div>
              <div style={{ marginLeft: "30px" }}>
                Additional Comments: {selectedProduct.description || '.......'}
              </div>
            </>
          )}

          <div className="file-upload" style={{ marginLeft: "30px" }}>
            <h2>Documents</h2>
            {/* Display uploaded documents */}
            {selectedProduct && selectedProduct.documents && selectedProduct.documents.length > 0 ? (
  selectedProduct.documents.map((file, index) => (
    <div key={file.id}>{file.name}</div>
  ))
) : (
  <div>No documents</div>
)}

          </div>

          <div className="file-upload" style={{ marginLeft: "30px" }}>
            <h2>Product Image</h2>
            {/* Display product image */}
            {/* {console.log(selectedProduct.image,"selectedProduct.product_image")} */}
            {!selectedProduct.image  && (<>NO IMAGE</>)}
            {selectedProduct && selectedProduct.image  && (
              <img
                src={product_image_address+selectedProduct.image}
                alt="Product"
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ViewProduct;
