import React, { createContext, useContext, useState,useEffect } from "react";

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);
export const UserProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [extraData, setExtraData] = useState({}); // Add extraData here
  const [showExtraData, setShowExtraData] = useState(true);
  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");

  const updateLocationName = (role) => {
    setLocationName(role);
  };
  const updateLocationAddress = (role) => {
    setLocationAddress(role);
  };
   useEffect(() => {
    const storedUserRole = localStorage.getItem("userRole");
    if (storedUserRole) {
      setUserRole(storedUserRole);
     // console.log(storedUserRole,"Stored ROLE")
    }
  }, []);

  const updateUserRole = (role) => {
    setUserRole(role);
    localStorage.setItem("userRole", role);

  };
  const logout = () => {
    setUserRole(null);
    localStorage.removeItem("userRole");
    window.location.href = "/";
  };

    // Function to update extraData
    const updateExtraData = (data) => {
      setExtraData(data);
    };

    const setShowExtraD = (data) => {
      setShowExtraData(data);
    };
  return (
    <UserContext.Provider value={{ userRole, updateUserRole, logout,extraData,updateExtraData,setShowExtraD, showExtraData,locationName,updateLocationName,locationAddress,updateLocationAddress}}>
      {children}
    </UserContext.Provider>
  );
};
