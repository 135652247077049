import axios from "axios";

const instance = axios.create({
 
//baseURL: 'http://localhost:8000',




  baseURL: "  https://aerialdemo.intelly.ca",
  timeout: 100000, 
  headers: {
    "X-Csrf-Token": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  },







  //baseURL: "  https://asset.intelly.ca",
  // timeout: 100000, 
  // headers: {
  //   "X-Csrf-Token": document
  //     .querySelector('meta[name="csrf-token"]')
  //     .getAttribute("content"),
  // },
});

export default instance;
