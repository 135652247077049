import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "60%",
    minHeight: "350px",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditUserModal = ({ isOpen, onRequestClose, UserData, onSave }) => {
  const initialFormData = {
    id: "",
    name: "",
    email: "",
    department_ids: [],
    password: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [passwordError, setPasswordError] = useState("");

  // When We Get UserData For Edit
  useEffect(() => {
    if (UserData) {
      setSelectedLocationId(null);
      if (UserData.department_permissions) {
        const departmentIds = UserData.department_permissions.map(
          (permission) => permission.department_id
        );
        setFormData({
          id: UserData.id,
          name: UserData.name || "",
          email: UserData.email || "",
          department_ids: departmentIds || "",
        });
      } else {
        setFormData({
          id: UserData.id,
          name: UserData.name || "",
          email: UserData.email || "",
          department_ids: "",
        });
      }

      // console.log(UserData);

      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        setLocations(locdata);
        setDepartments(deptdata);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [UserData]);

  // When User Selects New Location
  useEffect(() => {
    const filterDep = departments.filter(
      (department) => department.location_id === Number(selectedLocationId)
    );
    setFilteredDepartments(filterDep);
  }, [selectedLocationId]);

  // When CheckBox is Changed
  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        department_ids: [...formData.department_ids, Number(value)],
      });
    } else {
      setFormData({
        ...formData,
        department_ids: formData.department_ids.filter((id) => id !== Number(value)),
      });
    }
  };

  // When Location is Changed
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location_id") {
      if (value === "") {
        // Reset the filteredDepartments when no location is selected
        setFilteredDepartments([]);
      } else {
        setSelectedLocationId(value);
      }
    } else if (name === "name" || name === "email") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "password") {
      setFormData({
        ...formData,
        [name]: value,
      });
      if (value.length < 8 && value.length>0) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }
    }
  };

  // When Saving
  const handleSubmit = () => {
    // Check if the password meets the minimum length requirement
    if (formData.password) {
      if (formData.password.length < 8 && formData.password.length > 0) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        // No password error, proceed with saving
        setPasswordError("");
        onSave(formData);
        onRequestClose();
        // console.log(formData.department_ids);
      }
    } else {
      onSave(formData);
      onRequestClose();
      // console.log(formData.department_ids);
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit User"
    >
      <div style={{ textAlign: "center" }}>
        <h2>Edit User</h2>
        <form className="myFormEdit">
          <label>
            Name:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
              required
            />
          </label>
          <label style={{ marginLeft: "15px" }}>
            Email:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
            />
          </label>
          <label style={{ marginLeft: "15px" }}>
            Change Password:
            <input
              type="password"
              style={{ marginLeft: "15px" }}
              name="password"
              value={formData.password || ""}
              onChange={handleChange}
            />
            {passwordError && <p style={{ color: "red", margin: 0 }}>{passwordError}</p>}
          </label>

          <br />
          <br />
          <h2>Permissions</h2>
          <label>
            Location:
            <select
              style={{ marginLeft: "15px" }}
              name="location_id"
              onChange={handleChange}
            >
              <option> Select Location </option>
              {locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.store_name}
                </option>
              ))}
            </select>
          </label>

          <label style={{ marginLeft: "15px" }}>
            Department:
            <div style={{ display: "flex", flexDirection: "column" }}>
              {filteredDepartments.map((department) => (
                <label key={department.id}>
                  <input
                    type="checkbox"
                    name="department_ids"
                    value={department.id}
                    checked={formData.department_ids.includes(department.id)}
                    onChange={handleCheckboxChange}
                  />
                  {department.name}
                </label>
              ))}
            </div>
          </label>
          <br />
          <br />

          <button
            style={{ marginTop: "10px" }}
            type="button" // Change the button type to "button"
            onClick={handleSubmit} // Use onClick instead of onSubmit
          >
            Update
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default EditUserModal;
