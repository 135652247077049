import api from "../api";

export const EditUserToDB = async (updatedData) => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    // console.log(updatedData);
    const response = await api
      .put(`/api/user/${updatedData.id}`, updatedData)
      .then(() => {
        alert("Edit Done !");
      });
    return response;
  } catch (error) {
    console.error(error);
  }
};
