import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";

import { fetchDepartments } from "../FetchData/FetchDepartments";
import AddDepartments from "../AddPages/AddDepartments";
import "./Departments.css";
import "../globalstyle.css";
import { DeleteDepartment } from "../DeleteFromDB/DeleteDepartment";
import EditDepartmentModal from "../EditPages/EditDepartmentModal";
import { EditDepartmentToDB } from "../EditToDB/EditDepartmentToDB";

const Departments = () => {
  const [showAddDepartmentForm, setShowAddDepartmentForm] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDepartmentData, setEditDepartmentData] = useState(null);
  const [departmentChangeFlag, setDepartmentChangeFlag] = useState(true);

//For Pagination And Search
const [pageRange, setPageRange] = useState({ start: 0, end: 4 });
const [itemsPerPage, setItemsPerPage] = useState(10); 
const [currentPage, setCurrentPage] = useState(0);
const [paginatedData, setpaginatedData] = useState([]);
const [locationFilter, setLocationFilter] = useState("All");
const [filteredData, setFilteredData] = useState([]); // Maintain a copy of filtered data

const uniqueLocations = Array.from(
  new Set(departments.map((item) => item.location.store_name))
);
let data = { nodes: paginatedData };
const [search, setSearch] = React.useState('');
const handleSearchName = (event) => {
  setSearch(event.target.value);
  setItemsPerPage(departments.length);
  const filteredData = paginatedData.filter((item) =>
    item.name.toLowerCase().includes(event.target.value.toLowerCase())
  );
  //console.log(data.nodes);
};
const handleSearchLocation = (event) => {
  setSearch(event.target.value);
  setItemsPerPage(departments.length);
  const filteredData = paginatedData.filter((item) =>
    item.location.store_name.toLowerCase().includes(event.target.value.toLowerCase())
  );
  //console.log(data.nodes);
};
data = {
  nodes: data.nodes.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()) ||
    item.location.store_name.toLowerCase().includes(search.toLowerCase())
  ),
};
const handleItemsPerPageChange = (value) => {
  setItemsPerPage(value);
  setCurrentPage(0); 
};


useEffect(() => {
 // console.log("NOW Getting new Departments....");
  async function fetchDepartmentsData() {
    try {
      const data = await fetchDepartments();
      setdepartments(data);
    } catch (error) {
      console.error(error);
    }
  }
  fetchDepartmentsData();
}, [departmentChangeFlag]);

useEffect(() => {
  let myfilteredData = departments;
  if (locationFilter !== 'All') {
    myfilteredData = myfilteredData.filter((item) => item.location.store_name === locationFilter);
  }
  setFilteredData(myfilteredData);
},[locationFilter,departments]);

useEffect(() => {
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newpaginatedData = filteredData.slice(startIndex, endIndex);
  setpaginatedData(newpaginatedData);
  const maxPage = Math.max(1, Math.ceil(filteredData.length / itemsPerPage));  
  setCurrentPage((prevPage) => {
    if (prevPage < 0) return 0;
    if (prevPage >= maxPage) return maxPage - 1;
    return prevPage;
  });
  const maxVisiblePageButtons = 5;
    const newStart = Math.min(
    Math.max(0, currentPage - Math.floor(maxVisiblePageButtons / 2)),
    Math.max(0, maxPage - maxVisiblePageButtons)
  );
  const newEnd = Math.min(newStart + maxVisiblePageButtons, maxPage);
  setPageRange({ start: newStart, end: newEnd-1 });

  }, [itemsPerPage,currentPage,filteredData]);


  const handleJumpToPage = (page) => {
    setCurrentPage(page);
  };
  

//For Pagination And Search



  const toggleAddDepartmentForm = () => {
    setShowAddDepartmentForm(!showAddDepartmentForm);
  };
  function onSortChange(action, state) {
   // console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
          background-color: #F2F2F2;
        `,
    Row: `
          &:nth-of-type(odd) {
            background-color: #FFFFF;
          }
  
          &:nth-of-type(even) {
            background-color: #F2F2F2;
          }
        `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        DEPT_ID: (array) =>
          array.sort((a, b) => {
            const idA = a.id.toString();
            const idB = b.id.toString();
            return idA.localeCompare(idB);
          }),
        DEPT_NAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        DEPT_DESC: (array) =>
          array.sort((a, b) => {
            const descriptionA = (a.description || "").toString();
            const descriptionB = (b.description || "").toString();
            return descriptionA.localeCompare(descriptionB);
          }),
        DEPT_DATE: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
        DEPT_LOC_NAME: (array) =>
          array.sort((a, b) => a.location_id.localeCompare(b.location_id)),
        DEPT_LOC_ID: (array) =>
          array.sort((a, b) => a.location_id - b.location_id),
      },
    }
  );
  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };
  const confirmDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Department? This Will Delete All Products and Categories linked with this Department..?"
      )
    ) {
      handleDelete(id);
    }
  };
  const handleDelete = async (id) => {
    try {
      await DeleteDepartment(id).then(() => {
        setdepartments(
          departments.filter((department) => department.id !== id)
        );
      });
    } catch (error) {
      alert("Something Went Wrong in Delete ");
      console.error(`Error deleting Department with ID ${id}:`, error);
    }
  };

  

  function handleEdit(categoryData) {
    setEditDepartmentData(categoryData);
    setShowEditModal(true);
  }
  const closeEditModal = () => {
    setShowEditModal(false);
    setEditDepartmentData({});
  };
  const handleSaveEdit = async (updatedData) => {
    try {
      await EditDepartmentToDB(updatedData);
      setDepartmentChangeFlag(!departmentChangeFlag);
    } catch (error) {
      console.error(
        `Error updating Department with ID ${updatedData.id}:`,
        error
      );
      alert("Something Went Wrong in Edit ");
    }
  };


  return (
    <div>
      {showAddDepartmentForm ? (
        <AddDepartments toggleAddDepartmentForm={toggleAddDepartmentForm} departmentChangeFlag={departmentChangeFlag} setDepartmentChangeFlag={setDepartmentChangeFlag}/>
      ) : (
        <>
          <div className="text-parent">
            <h2 className="welcomeText">Departments</h2>
            <button className="add-button" onClick={toggleAddDepartmentForm}>
              {" "}
              + Add New
            </button>
          </div>
         
{/* //For Pagination And Search */}
<div className="mySearch" style={{ display: "flex" }}>
  <label htmlFor="search">
    Search :
    <input id="search" type="text" onChange={handleSearchName} />
  </label>

  <div className="location-filter" style={{ marginLeft: "10px" }}>
    <label htmlFor="location-filter">Filter by Location: </label>
    <select
      id="location-filter" // Unique id for location dropdown
      value={locationFilter}
      onChange={(e) => setLocationFilter(e.target.value)}
    >
      <option value="All">All</option>
      {uniqueLocations.map((location) => (
        <option key={location} value={location}>
          {location}
        </option>
      ))}
    </select>
  </div>
</div>
{/* //For Pagination And Search */}

          <div className="myTable" style={{ marginTop: "60px" }}>
            <Table data={data} sort={sort} theme={theme} >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_ID"
                      >
                        ID
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_NAME"
                      >
                        Name
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DESC"
                      >
                        Description
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_NAME"
                      >
                        Location
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_ID"
                      >
                        Location ID
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DATE"
                      >
                        Date Added
                      </HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">Edit</HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">
                        Delete
                      </HeaderCellSort>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item) => (
                      <Row item={item} key={item.id}>
                        <Cell>{item.id}</Cell>
                        <Cell>{item.name}</Cell>
                        <Cell>{item.description}</Cell>
                        <Cell>{item.location.store_name}</Cell>
                        <Cell>{item.location_id}</Cell>
                        <Cell>
                          {item.created_at
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </Cell>
                        <Cell>
                          <IconButton
                            color="primary"
                            aria-label="Edit"
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Cell>
                        <Cell>
                          <IconButton
                            color="secondary"
                            aria-label="Delete"
                            onClick={() => confirmDelete(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </div>

{/* //For Pagination And Search */}
<div className="myPagination" style={{display:"flex"}}>
<div className="pagination-controls" style={{marginRight:"10px"}}> 
  <button
    onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
    disabled={currentPage === 0}
  >
    Previous
  </button>
  <button
    onClick={() =>
      setCurrentPage(Math.min(currentPage + 1, Math.ceil(departments.length / itemsPerPage) - 1))
    }
    disabled={currentPage === Math.ceil(departments.length / itemsPerPage) - 1}
  >
    Next
  </button>
</div>

     {/* Page navigation */}
     {Math.ceil(departments.length / itemsPerPage) > 1 && (
              <div>
                {Array.from({ length: pageRange.end - pageRange.start + 1 }).map(
                  (_, index) => {
                    const page = pageRange.start + index;
                    return (
                      <button
                        key={page}
                        onClick={() => handleJumpToPage(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                )}
              </div>
            )}


<select
style={{marginLeft: "5px"}}
  value={itemsPerPage}
  onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}
>
<option value={1}>1 per page</option>
  <option value={10}>10 per page</option>
  <option value={20}>20 per page</option>
  <option value={50}>50 per page</option>
  <option value={departments.length}>All</option>

</select>

<div style={{marginLeft:"5px"}}>
    Total Records: {departments.length}
  </div>

</div>
{/* //For Pagination And Search */}

          <EditDepartmentModal
            isOpen={showEditModal}
            onRequestClose={closeEditModal}
            DepartmentData={editDepartmentData}
            onSave={handleSaveEdit}
          />
        </>
      )}
    </div>
  );
};

export default Departments;
