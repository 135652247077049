import api from "../api";

export const EditProductToDB = async (updatedData) => {
// console.log(updatedData.image); 
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const response = await api
      .post(`/api/editproduct/${updatedData.id}`, updatedData)
      .then(() => {
        alert("Edit Done !");
      });
    return response;
  } catch (error) {
    console.error(error);
  }
};
