import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "60%",
    minHeight: "350px",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditCategoryModal = ({
  isOpen,
  onRequestClose,
  CategoryData,
  onSave,
}) => {
  const initialFormData = {
    id: "",
    name: "",
    description: "",
    location_id: "",
    department_id: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  useEffect(() => {
    if (CategoryData) {
      // console.log(CategoryData.name);
      setFormData({
        id: CategoryData.id,
        name: CategoryData.name || "",
        description: CategoryData.description || "",
        location_id: CategoryData.location_id || "",
        department_id: CategoryData.department_id || "",
      });
      setSelectedLocationId(CategoryData.location_id);
      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        setLocations(locdata);
        setDepartments(deptdata);
        // console.log(locations);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [CategoryData]);

  useEffect(() => {
    const filterDep = departments.filter(
      (department) => department.location_id === Number(selectedLocationId)
    );
    setFilteredDepartments(filterDep);
  }, [departments, selectedLocationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location_id") {
      // console.log(value, "loc");
      setSelectedLocationId(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSave(formData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Location"
    >
      <div style={{ textAlign: "center" }}>
        <h2>Edit Category</h2>
        <form className="myFormEdit" onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
              required
            />
          </label>
          <label style={{ marginLeft: "15px" }}>
            Description:
            <input
              type="text"
              style={{ marginLeft: "15px" }}
              name="description"
              value={formData.description || ""}
              onChange={handleChange}
            />
          </label>
          <br></br>
          <label>
            Location:
            <select
              style={{ marginLeft: "15px" }}
              name="location_id"
              value={formData.location_id || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.store_name}
                </option>
              ))}
            </select>
          </label>

          <label style={{ marginLeft: "15px" }}>
            Department:
            <select
              style={{ marginLeft: "15px" }}
              name="department_id"
              value={formData.department_id || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select Department</option>
              {filteredDepartments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </label>
          <br></br>
          <button style={{ marginTop: "10px" }} type="submit">
            Update
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default EditCategoryModal;
