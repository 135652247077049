import api from "../api";
export const EditDepartmentToDB = async (updatedData) => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api
      .put(`/api/departments/${updatedData.id}`, updatedData)
      .then(() => {
        alert("Edit Done !");
      });
    return response;
  } catch (error) {
    console.error(error);
  }
};
