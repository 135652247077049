import api from "../api";
export const AddUserToDB = async (formDataWithFiles) => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api.post("/api/user", formDataWithFiles);
    // console.log(response.data);
    alert(response.data.message);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
