import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import "../globalstyle.css";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import DepartmentsDropdown from "../Dropdowns/DepartmentsDropdown";
import { ImportCategoriesToDB } from "../ImportToDB/ImportCategoriesToDB";

const ImportCategories= ({locations,departments}) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileInputKey, setFileInputKey] = useState(0); // Add new state for file input key

    useEffect(() => {
        setSelectedFiles(null);
      }, [fileInputKey]); // Reset file input when the key changes

useEffect(() => {
      setSelectedFiles(null);
  }, []);

  const handleDepartmentChange = (departmentId) => {
    setSelectedDepartment(departmentId);
    setSelectedFiles(null);
    setFileInputKey((prevKey) => prevKey + 1);

  };
  const handleLocationChange = (locationId) => {
    setSelectedLocation(locationId);
    setSelectedDepartment(null);
    setFileInputKey((prevKey) => prevKey + 1);
    setSelectedFiles(null);


  };

const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = selectedFiles[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          // const cellA1 = firstSheet['A1'];
          // const cellB1 = firstSheet['B1'];
          // const cellC1 = firstSheet['C1'];
          // const cellD1 = firstSheet['D1'];
          // const cellE1 = firstSheet['E1'];
          // const cellF1 = firstSheet['F1'];
          // const cellG1 = firstSheet['G1'];
          // const cellH1 = firstSheet['H1'];
          // const cellI1 = firstSheet['I1'];
          // const cellJ1 = firstSheet['J1'];
          // const cellK1 = firstSheet['K1'];
          // const cellL1 = firstSheet['L1'];
          // const cellM1 = firstSheet['M1'];
          // const cellN1 = firstSheet['N1'];
          // const cellO1 = firstSheet['O1'];

          // const mycols = [cellA1.v,cellB1.v,cellC1.v,cellD1.v,cellE1.v,cellF1.v,cellG1.v,cellH1.v,cellI1.v,cellJ1.v,cellK1.v,cellL1.v,cellM1.v,cellN1.v,cellO1.v];
          // console.log('mycols', mycols);
   // Extract column names from the first row of the sheet
   const sheetHeaders = [];
   for (let col in firstSheet) {
     if (/^[A-Z]+\d+$/.test(col)) {
       const rowIndex = Number(col.match(/\d+/)[0]);
       if (rowIndex === 1) {
         sheetHeaders.push(firstSheet[col].v);
       }
     }
   }
   
//    console.log('Headers in the Excel file:', sheetHeaders);
   const expectedColumns = [
    'name',
    'description',
  ];
          const missingColumns = expectedColumns.filter((header) => !sheetHeaders.includes(header));
          if (missingColumns.length === 0) {
            // console.log('Excel file structure is valid');
            try{
            ImportCategoriesToDB(selectedFiles[0],selectedLocation,selectedDepartment).then(()=>{
            //   console.log("OOOO");
              setSelectedFiles(null);
              setFileInputKey((prevKey) => prevKey + 1);

            });
          }
          catch (error) {
            console.log(error,"Error");    
            setSelectedFiles(null);
            setFileInputKey((prevKey) => prevKey + 1);

    
          }
          } else {
            //console.error('Missing columns:', missingColumns);
            alert("EXCEL NOT VALID");
          }
        } catch (error) {
          console.error('Error reading Excel file:', error.message);
        }
      };
  };
  reader.readAsArrayBuffer(file);
//   console.log("OOO");
//   console.log(selectedFiles[0]);
  }
  return (
  <>
  <div style={{margin: "31px",textAlign: "center",width: "fit-content",background: "#005668",
fontSize: "xx-large",
padding: "10px",
borderRadius: "10px"}}>
<a href={"https://aerialdemo.intelly.ca/categories%20-%20Sample.xlsx"} target="_blank" rel="noopener noreferrer" style={{color:"white"}} >
Download Sample For Categories Import
</a>
</div>
<br></br>
<div>
{locations.length>0 ?
        (<>
        {departments.length>0 ?
          (
            <div>
            <LocationsDropdown
              locations={locations}
              selectLocation={selectedLocation}
              setSelectLocation={handleLocationChange}
            />
                      <br></br>
  
            {selectedLocation && (
              <DepartmentsDropdown
                departments={departments}
                selectDepartment={selectedDepartment}
                selectedLocation={selectedLocation}
                setSelectDepartment={handleDepartmentChange}
              />
  
                )}
                        <br></br>
  
            {selectedLocation && selectedDepartment && (
                <>
                <input
                key={fileInputKey} 
type="file"
accept=".xlsx"
onChange={(e)=>{setSelectedFiles(e.target.files)
}}
style={{ fontSize: '22px', padding: '10px' }}
/>               
{selectedFiles ? (<>
    <button
    type="button"
    className="importButton"
    onClick={handleFileUpload}
    style={{ fontSize: '18px', padding: '12px' }}
    >
    Import
    </button>
    </>):(<></>)}
</>
)}
          </div>
          ):(<div>No Departments</div>)
          }
         </>
      )   
        :(<div>
          No Locations
        </div>)
}

  </div>
  </>
  )
}
  export default ImportCategories;
