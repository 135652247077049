import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3"; // Import D3.js
import { fetchProducts } from "./FetchData/fetchProducts";
import { fetchLocations } from "./FetchData/FetchLocations";
import transformData from "./CirclesViewFunctions/transformData";
import "./CirclesView.css";
import "./globalstyle.css";
import { useUserContext } from "./UserContext";
import { Visibility } from "@mui/icons-material";


function CirclesView({ circleColor, departmentColor, categoryColor, productColor }) {
  const { updateExtraData,extraData,setShowExtraD,showExtraData,updateLocationName,updateLocationAddress } = useUserContext();
  const [transformedProductJsonData, setTransformedProductJsonData] = useState({});
  const [products, setProducts] = useState({});
  const [extraDataLocations, setExtraDataLocations] = useState("");
  const [selectedLocationID, setSelectedLocationID] = useState(0);
  const svgRef = useRef(null);
  const svgDivRef = useRef(null);
  const svgContainerRef = useRef(null);
  var
	simulation,
	node, link,
	linkOpacity = 0.4,
	linkPaths = false,// false for straight lines, true for curved paths
	textVisible = true,
	needCollapse = false,
	captionFontSizes = [24, 11, 10, 8],
   maxLabelFontSize = 24,
	maxRadius = 120,
	locationIndex = 0,
	locationNames = [],
	showLocation = 0,
	colors = [circleColor,departmentColor,categoryColor,productColor],
	maxDepth;
  useEffect(() => {
    const width = svgContainerRef.current.offsetWidth;
    const height = svgContainerRef.current.offsetHeight;
fetchLocations().then((locationextradata) => {
  setExtraDataLocations(locationextradata); 
  fetchProducts()
    .then((productdata) => {
      setProducts(productdata);
      if(productdata.length>0){
      const transformedproductData = transformData(productdata);
      if (transformedproductData[0]) {
       // console.log(transformedData[0]);
       var graph = d3.hierarchy(transformedproductData[0]);
    var nodes = flatten(graph.children[showLocation]);
    maxDepth = d3.max(nodes, function(d) { return +d.depth;} );
    var links = graph.children[showLocation].links();

    for (var i = 0; i < graph.children.length; i++) {
      locationNames.push(graph.data.children[i].name);
    }
    
    nodes.forEach(collapse);
    const transform = d3.zoomIdentity;

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    const svg = d3.select(svgContainerRef.current).append('svg')
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("width", "100%")
      .attr("height", "100%")
      .call(d3.zoom().scaleExtent([1/2, 8]).on('zoom', zoomed));
    
      const glinks = svg
      .append('g')
      .attr("id", "links");
    // nodes' group "layer" has to be defined later so link paths are always below nodes
    
    const gnodes = svg
      .append('g')
      .attr("id", "nodes");

    simulation = d3.forceSimulation()
      .force('link', d3.forceLink().id( function(d) { return d.id; }))
      .force('charge', d3.forceManyBody().strength(-70).distanceMin(100).distanceMax(300))
      .force('center', d3.forceCenter( width/2, height/2 ))
      .force("collide", d3.forceCollide().strength(0.1).radius(function(d) {// console.log(d);
         return (d.children !== undefined && d.children !== null && d.children.length == 1 && d.depth == 1) ? (5*maxRadius)/d.depth : (1.8*maxRadius)/d.depth }))
      .velocityDecay(0.3)
      .on('tick', ticked)
    // Collapse the node and all it's children
    function collapse(d) {
      if (d.children) {
        d._children = d.children
        d._children.forEach(collapse)
        d.children = null
      }
    }
    function collapseAll() {
      collapse(nodes);
      update(showLocation);
    }
    function update(location) {
      var linkEnter;
      if(location<1){
        const desiredLocation = locationextradata.find(location => location.store_name === locationNames[showLocation]);
//console.log((desiredLocation),"desiredLocation");
updateLocationName(desiredLocation.store_name);
updateLocationAddress(desiredLocation.address +
          "," +
          desiredLocation.city +
             "," +
             desiredLocation.state +
             "," +
             desiredLocation.country +
             "," +
             desiredLocation.zip_code,);

        const someExtraData = {
          store_name: desiredLocation.store_name,
          location_manager_name: desiredLocation.location_manager_name,
          location_manager_phone: desiredLocation.location_manager_phone,
          area_manager_name:desiredLocation.area_manager_name,
          area_manager_phone:desiredLocation.area_manager_phone,
          retail_code:desiredLocation.retail_code,
          mon_to_fri_opening:desiredLocation.mon_to_fri_opening,
          mon_to_fri_closing:desiredLocation.mon_to_fri_closing,
          sat_to_sun_opening:desiredLocation.sat_to_sun_opening,
          sat_to_sun_closing:desiredLocation.sat_to_sun_closing,
        };
      
        updateExtraData(someExtraData);
      }
      if (showLocation !== location) {
        showLocation = location;
        needCollapse = true;
      //  console.log(showLocation,"SHOW indexlocation in loca after 0"); //getting indexlocation in locations array 
        //console.log(locationNames[showLocation],"SHOW indexlocation in loca after 0"); //getting indexlocation in locations array 
//console.log((locationextradata),"locationextradata");
const desiredLocation = locationextradata.find(location => location.store_name === locationNames[showLocation]);
//console.log((desiredLocation),"desiredLocation");
updateLocationName(desiredLocation.store_name);
updateLocationAddress(desiredLocation.address +
          "," +
          desiredLocation.city +
             "," +
             desiredLocation.state +
             "," +
             desiredLocation.country +
             "," +
             desiredLocation.zip_code,);

        const someExtraData = {
          store_name: desiredLocation.store_name,
          location_manager_name: desiredLocation.location_manager_name,
          location_manager_phone: desiredLocation.location_manager_phone,
          area_manager_name:desiredLocation.area_manager_name,
          area_manager_phone:desiredLocation.area_manager_phone,
          retail_code:desiredLocation.retail_code,
          mon_to_fri_opening:desiredLocation.mon_to_fri_opening,
          mon_to_fri_closing:desiredLocation.mon_to_fri_closing,
          sat_to_sun_opening:desiredLocation.sat_to_sun_opening,
          sat_to_sun_closing:desiredLocation.sat_to_sun_closing,
        };
      
        updateExtraData(someExtraData);



        link = null;
        node = null;
        svg.selectAll('.text').remove();
        svg.selectAll('.node').remove();
        svg.selectAll('.line').remove();
      }	
      var nodes = flatten(graph.children[location]);
      var links = graph.children[location].links();
      if (needCollapse == true) {
        nodes.forEach(collapse);
        needCollapse = false;
      }
      link = glinks
        .selectAll('.link')
        .data(links, function(d){ return d.target.id })
      link.exit().remove()
      if (linkPaths == true) {
        linkEnter = link
          .enter()
          .append("path")
          .attr("class", "link");
      } else {
        linkEnter = link
          .enter()
          .append("line")
          .attr("class", "link");
      };
      
      linkEnter
        .style('stroke', 'black' )
        .style('opacity', linkOpacity)
        .style('stroke-width', "1px")
      
      link = linkEnter.merge(link)
      
      node = gnodes
        .selectAll('.node')
        .data(nodes, function(d){ return d.id })
  
      node.exit().remove()
  
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('stroke', '#666')
        .attr('stroke-width', 1)
        .style('fill', color)
        .attr("fx", width / 2)
        .attr("fy", height / 2)
        .on('click', clicked)
        .on("mouseover", function(event,d) {
          if (d.depth == maxDepth) {
            div.transition()
             .duration(200)
             .style("opacity", .9);
            div.html(d.data.name + "<br/>")
             .style("left", (event.pageX) + "px")
             .style("top", (event.pageY - 28) + "px");
          }
         })
        .on("mouseout", function(d) {
         div.transition()
           .duration(500)
           .style("opacity", 0);
         })
        .call(d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended))
      
            nodeEnter.filter(function(d) { return (d.depth == 1) }).classed("fixed", true); // fixes root node at center
      nodeEnter.append('circle')
    .attr("r", function(d, r) { 
      if (d.depth > 1) {
          if (d.depth=== 2) {
              return 60;
          } else if (d.depth === 3) {
              return 50;
          } else if (d.depth === 4) {
              return 45;
          } else {
              return ((maxRadius/4) + 48) / d.depth;
          }
      } else {
          return maxRadius;
  }
  })

        .attr("id", d => "id" + d.id)
          .attr("stroke-width", "0.5px")
        .on("click", function(d) { d3.select(this.parentNode).raise() })
  
      if (textVisible) {
        var deps = false;
        var text = nodeEnter.append("text")
          .attr('class', 'text')
          .attr("dx", 0)
          .attr("dy", function(d) { return (d.depth > 1 ? "0.35em" : "0em") })
          .attr("font-size", function(d) { return (d.depth > 1 ? captionFontSizes[d.depth-1] : maxLabelFontSize ) + "px"; })
         .attr("text-anchor", "middle")
          .attr("lengthAdjust", "spacingAndGlyphs")
        //  .attr("textLength", function(d) { return (d.depth > 1 ? 2*((maxRadius/4)+48)/d.depth : 3*maxRadius ) })
          .text(function(d) { return d.data.name })
        text.append("tspan")
          .attr("x", "0%")
          .attr("dy", "1.2em")
          .attr("style", "font-size: 14px")
          .attr("text-anchor", "middle")
          .text(function(d) {
            if (d.depth == 1) { 
              return "Departments: " + d.data.children.length } 


          })
      }
      	// add dropdown menu of locations

    
		var l = 1;
		for (i=0; i<locationNames.length; i++) { if (l<locationNames[i].length) l = locationNames[i].length }
		l= l*10
		var mainNode = nodeEnter.filter(function(d) { return (d.depth == 1) })
			.append("g")
			.attr("class", "dropdown")
		var select = mainNode
			.append("g")
			.attr("class", "select")
		select.append("rect")
			.attr("x", -l/3)
			.attr("y",  22)
			.attr("width", l/1.5)
			.attr("height", 18)
      .style("fill",circleColor)
		select.append("text")
			.attr("x", -l/3 +4)
			.attr("y", 36)
			.attr("id", "mydropdown")
			.text(locationNames[locationIndex])

		var options = select.selectAll(".myBars")
			.data(locationNames)
			.enter()
			.append("g")
			
		options.attr("class", "option")
			.on("click", clickedLocation)
			
		options.append("rect")
			.attr("x", -l/3)
			.attr("y", function(d,i){ return 40 + i*18})
			.attr("width", l)
      .style("fill",circleColor)
			.attr("height", 18)

		 options.append("text")
			.attr("x", function(d){ return -l/3 +4})
			.attr("y", function(d,i){ return 54 + i*18})
			.text(function(d){ return d})

      node = nodeEnter.merge(node)
    
      // Restart the force layout.
      simulation.nodes(nodes)
      simulation.force('link').links(links)
      simulation.alpha(0.1).restart()
    } 
    function color(d) {
      return colors[d.depth-1];
    }
   function ticked() {
  nodes[nodes.length - 1].x = width / 2;
  nodes[nodes.length - 1].y = height / 2;

  var alpha = this.alpha();
  var chargeStrength = 0.1;

  if (alpha > 0.2) {
    chargeStrength = (alpha - 0.2) / 0.8;
  } else {
    chargeStrength = 0;
  }

  this.force(
    "charge",
    d3
      .forceManyBody()
      .strength(function (d) {
        // Adjust charge strength based on the number of children
        if (d.children && d.children.length === 1) {
          return -50; // Adjust this value as needed
        } else {
          return -100 * chargeStrength;
        }
      })
  );

  // Rest of the ticked function remains unchanged
  if (linkPaths == true) {
    // Set curved path of a link
    link.attr("d", function (d) {
      var dx = d.target.x - d.source.x,
        dy = d.target.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy);
      return (
        "M" +
        d.source.x +
        "," +
        d.source.y +
        " A" +
        dr +
        "," +
        dr +
        " 0 0,1 " +
        d.target.x +
        "," +
        d.target.y
      );
    });
  } else {
    // Set ordinary straight lines
    link
      .attr("x1", function (d) {
        return d.source.x;
      })
      .attr("y1", function (d) {
        return d.source.y;
      })
      .attr("x2", function (d) {
        return d.target.x;
      })
      .attr("y2", function (d) {
        return d.target.y;
      });
  }

  node.attr("transform", function (d) {
    return `translate(${d.x}, ${d.y})`;
  });
}

    function clicked(event, d) {
      if (!event.defaultPrevented) {
       // console.log(d);
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          if(d.depth<4){
          if (d.depth == 1) {
             d.fx = width/2; d.fy = height/2; d.fixed = true; 
            };// forcing root node to be fixed at center
          switch (d.children.length ) {
            case 1:
            console.log(d.x);		
            console.log(d.y);
              d.children[0].x = (d.x > (width/2) ? d.x + (document.querySelector("#id" + d.id).r.animVal.value*1.5) : d.x - (document.querySelector("#id" + d.id).r.animVal.value*1.5));		
d.children[0].y = (d.x > (height/2) ? d.y + (document.querySelector("#id" + d.id).r.animVal.value*1.5) : d.y - (document.querySelector("#id" + d.id).r.animVal.value*1.5));		
console.log("only one child");		
break;		
default:
            d.children.forEach((el) => { el.x = d.x; el.y = d.y; });
        // this makes children nodes open from their parent center
        }
      }
      d._children = null;
      }
        update(showLocation)
       // console.log(showLocation,"SHOW LOCATION");
      }
    }
  	function clickedLocation(event, d) {
      event.stopPropagation();
      document.getElementById("mydropdown").textContent = d;
      locationIndex = locationNames.indexOf(d);
      update(locationIndex);
      collapseAll();
    }
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart()
      d.fx = d.x
      d.fy = d.y
    }
    function dragged(event, d) {
      if (d.depth !== 1) {
        d.fx = event.x
        d.fy = event.y
      }
    }
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0)
      d.fx = null
      d.fy = null
    }
    function flatten(root) {
      const nodes = []
      var i = 0;
      function recurse(node) {
        if (node.children) node.children.forEach(recurse)
        if (!node.id) node.id = ++i;
        else ++i;
        nodes.push(node)
      }
      recurse(root)
      //console.log("Flattened root");
      //console.log(nodes);
      return nodes
    }
    function zoomed(event, d) {
      gnodes.attr('transform', event.transform)
      glinks.attr('transform', event.transform)
    }  
    update(showLocation);
}
      setTransformedProductJsonData(transformedproductData); // Set transformed data here
      const svgElement = svgDivRef.current;

}
   // svgElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    })
    .catch((error) => console.error("Error fetching data:", error));
});

    
}, []); 

  return (
    <>
    {products.length>0 ?(<>
      {showExtraData? (<>
    </>):(<>
      <p style={{
  cursor: "pointer",
  writingMode: "vertical-rl",  // Vertical text from right to left
  textOrientation: "mixed",   // For mixed Latin and CJK characters
  whiteSpace: "nowrap",       // Prevent text from wrapping
  position: "fixed",
  left: "70px",
  top: "50%",
  transform: "translateY(-50%)", 
  fontSize: "larger",
  fontWeight:"bold" // Center vertically
}} onClick={() =>
  setShowExtraD(true)
}>Show Details</p>    </>)}
    </>):(<>
    {extraData.length>0? (<></>):(<><div>No Products</div></>)}
    </>)}

 
      <div ref={svgContainerRef} id="svgcontainer"></div>


  </>
  );
}

export default CirclesView;
