import React, { useState, useEffect } from "react";
import "../globalstyle.css";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import { fetchLocations } from "../FetchData/FetchLocations";
import { AddDepartmentsToDB } from "../AddToDB/AddDepartmentsToDB";

const AddDepartments = ({ toggleAddDepartmentForm ,departmentChangeFlag,setDepartmentChangeFlag}) => {
  const [formData, setFormData] = useState({
    location_id: 0,
    dept_name: "",
    dept_desc: "",
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    async function fetchLocationsData() {
      try {
        const data = await fetchLocations();
        setLocations(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchLocationsData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      AddDepartmentsToDB(formData).then(()=>{
        setDepartmentChangeFlag(!departmentChangeFlag);
        toggleAddDepartmentForm();
      })
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleAddDepartmentForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Add New Department</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <h2 htmlFor="">Department Location</h2>

          <LocationsDropdown
            locations={locations}
            selectLocation={formData.location}
            setSelectLocation={(value) =>
              setFormData({ ...formData, location_id: value })
            }
          />
          <br></br>

          <h2 htmlFor="">Department Details</h2>

          <div
            className="store-name"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "30px",
                width: "180px",
              }}
            >
              Department Name{" "}
            </div>
            <input
              type="text"
              name="dept_name"
              value={formData.dept_name}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "30px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              required
            />
          </div>
          <br></br>
          <div
            className="address"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "stretch",
            }}
          >
            <div style={{ marginLeft: "30px", width: "180px" }}>
              Description{" "}
            </div>
            <input
              type="text"
              name="dept_desc"
              value={formData.dept_desc}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "100px",
                marginBottom: "20px",
              }}
            />
          </div>

          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "50%",
              height: "38px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default AddDepartments;
