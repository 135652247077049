import React, { useState, useEffect } from "react";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import DepartmentsDropdown from "../Dropdowns/DepartmentsDropdown";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";
import { AddCategoriesToDB } from "../AddToDB/AddCategoriesToDB";
import "../globalstyle.css";

const AddCategories = ({ toggleAddCategoriesForm, setCategoryChangeFlag }) => {
  const [formData, setFormData] = useState({
    location_id: undefined,
    department_id: undefined,
    cat_name: "",
    cat_desc: "",
  });
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        setLocations(locdata);
        setDepartments(deptdata);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AddCategoriesToDB(formData).then(() => {
        setCategoryChangeFlag(true);
      });
      toggleAddCategoriesForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleAddCategoriesForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Add New Cateogry</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <h2 htmlFor="">Category Location</h2>
          <div style={{ display: "flex" }}>
            <LocationsDropdown
              locations={locations}
              selectLocation={formData.location_id}
              setSelectLocation={(value) =>
                setFormData({ ...formData, location_id: value })
              }
            />
            <br></br>
            {formData.location_id && (
              <DepartmentsDropdown
                departments={departments}
                selectDepartment={formData.department_id}
                selectedLocation={formData.location_id}
                setSelectDepartment={(value) =>
                  setFormData({ ...formData, department_id: value })
                }
              />
            )}
            <br></br>
          </div>
          <h2 htmlFor="">Cateogry Details</h2>
          <div
            className="store-name"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "30px",
                width: "180px",
              }}
            >
              Cateogry Name{" "}
            </div>
            <input
              type="text"
              name="cat_name"
              value={formData.cat_name}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "30px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              required
            />
          </div>
          <br></br>
          <div
            className="address"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "stretch",
            }}
          >
            <div style={{ marginLeft: "30px", width: "180px" }}>
              Description{" "}
            </div>
            <input
              type="text"
              name="cat_desc"
              value={formData.cat_desc}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "100px",
                marginBottom: "20px",
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "50%",
              height: "38px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default AddCategories;
