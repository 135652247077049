import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from "./UserContext";
import Layout from "./Layout";
import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";
import LoginPage from "./MainPages/LoginPage";
import Locations from "./MainPages/Locations";
import Categories from "./MainPages/Categories";
import Colors from "./MainPages/Colors";
import Departments from "./MainPages/Departments";
import Folder from "./MainPages/Folder";
import ImportData from "./MainPages/ImportData";
import Products from "./MainPages/Products";
import Table from "./MainPages/Table";
import Users from "./MainPages/Users";
import ForgotPassword from "./MainPages/ForgotPassword";
import ForgotPasswordNewPassword from "./MainPages/ForgotPasswordNewPassword";

function App() {
  return (
    <div className="MyMainApp">
      <Router>
        <UserProvider>
          <Routes>
          <Route path="/forgotnewpass/:email/:token"element={<ForgotPasswordNewPassword />}/>

            <Route path="/" element={<LoginPage />} />
            <Route
              element={
                <Layout>
                  <AdminDashboard />
                </Layout>
              }
              path="/admin"
            />
            <Route
              element={
                <Layout>
                  <UserDashboard />
                </Layout>
              }
              path="/user"
            />
            <Route
              path="/admin/locations"
              element={
                <Layout>
                  <Locations />
                </Layout>
              }
            />
            <Route
              path="/admin/categories"
              element={
                <Layout>
                  <Categories />
                </Layout>
              }
            />
            <Route
              path="/admin/colors"
              element={
                <Layout>
                  <Colors />
                </Layout>
              }
            />
            <Route
              path="/admin/departments"
              element={
                <Layout>
                  <Departments />
                </Layout>
              }
            />
            <Route
              path="/folder"
              element={
                <Layout>
                  <Folder />
                </Layout>
              }
            />
            <Route
              path="/admin/importdata"
              element={
                <Layout>
                  <ImportData />
                </Layout>
              }
            />
            <Route
              path="/admin/products"
              element={
                <Layout>
                  <Products />
                </Layout>
              }
            />
            <Route
              path="/admin/users"
              element={
                <Layout>
                  <Users />
                </Layout>
              }
            />
            <Route
              path="/table"
              element={
                <Layout>
                  <Table />
                </Layout>
              }
            />
            <Route
              path="/forgot"
              element={
                <Layout>
                  <ForgotPassword />
                </Layout>
              }
            />

  

          </Routes>
        </UserProvider>
      </Router>
    </div>
  );
}

export default App;
