import api from "../api";
export const AddLocationsToDB = async (formData) => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api.post("/api/locations", formData);
    alert(response.data.message);
    return response;
  } catch (error) {
    console.error(error);
  }
};
