import React, { useState, useRef,useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { fetchColors } from '../FetchData/FetchColors';
import { EditColors } from '../EditToDB/EditColors';
import '../globalstyle.css';

const Colors = () => {


  const [circleColor, setCircleColor] = useState("");
  const [departmentColor, setDepartmentColor] = useState("");
  const [categoryColor, setCategoryColor] = useState("");
  const [productColor, setProductColor] = useState("");
  const colorsAvailable = circleColor && departmentColor && categoryColor && productColor;

  const [tier1Color, setTier1Color] = useState(""); // Default color for Tier 1
  const [tier2Color, setTier2Color] = useState(""); // Default color for Tier 2
  const [tier3Color, setTier3Color] = useState(""); // Default color for Tier 3
  const [tier4Color, setTier4Color] = useState(""); // Default color for Tier 4

  // Define states for toggling color pickers for each tier
  const [showTier1ColorPicker, setShowTier1ColorPicker] = useState(false);
  const [showTier2ColorPicker, setShowTier2ColorPicker] = useState(false);
  const [showTier3ColorPicker, setShowTier3ColorPicker] = useState(false);
  const [showTier4ColorPicker, setShowTier4ColorPicker] = useState(false);

  // Define refs for the color picker components
  const tier1ColorPickerRef = useRef(null);
  const tier2ColorPickerRef = useRef(null);
  const tier3ColorPickerRef = useRef(null);
  const tier4ColorPickerRef = useRef(null);

  // Function to handle color div click for each tier
  const handleTier1ColorDivClick = () => {
    setShowTier1ColorPicker(!showTier1ColorPicker);
  };

  const handleTier2ColorDivClick = () => {
    setShowTier2ColorPicker(!showTier2ColorPicker);
  };

  const handleTier3ColorDivClick = () => {
    setShowTier3ColorPicker(!showTier3ColorPicker);
  };

  const handleTier4ColorDivClick = () => {
    setShowTier4ColorPicker(!showTier4ColorPicker);
  };

  // Function to handle color change for each tier
  const handleTier1ColorChange = (newColor) => {
    setTier1Color(newColor.hex);
  };

  const handleTier2ColorChange = (newColor) => {
    setTier2Color(newColor.hex);
  };

  const handleTier3ColorChange = (newColor) => {
    setTier3Color(newColor.hex);
  };

  const handleTier4ColorChange = (newColor) => {
    setTier4Color(newColor.hex);
  };

  // Function to close the color picker when clicking outside of it
  const handleOutsideClick = (e) => {
    if (
      tier1ColorPickerRef.current &&
      !tier1ColorPickerRef.current.contains(e.target)
    ) {
      setShowTier1ColorPicker(false);
    }

    if (
      tier2ColorPickerRef.current &&
      !tier2ColorPickerRef.current.contains(e.target)
    ) {
      setShowTier2ColorPicker(false);
    }

    if (
      tier3ColorPickerRef.current &&
      !tier3ColorPickerRef.current.contains(e.target)
    ) {
      setShowTier3ColorPicker(false);
    }

    if (
      tier4ColorPickerRef.current &&
      !tier4ColorPickerRef.current.contains(e.target)
    ) {
      setShowTier4ColorPicker(false);
    }
  };

  // Attach an event listener to handle clicks outside of the color pickers
  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
   await fetchColors().then((data)=>{
      setCircleColor(data[0].circle_color)
      setDepartmentColor(data[0].department_color)
      setCategoryColor(data[0].category_color)
      setProductColor(data[0].product_color)
      setTier1Color(data[0].circle_color)
      setTier2Color(data[0].department_color)
      setTier3Color(data[0].category_color)
      setTier4Color(data[0].product_color)
    });}

   fetchData();
  },[]);
  
const submitColors = ()=>{
//console.log("Submitting the Colors");

const newcolors = {
  circle_color: tier1Color,
  department_color: tier2Color,
  category_color: tier3Color,
  product_color: tier4Color,
};

EditColors(newcolors).then(()=>{
  //console.log("DONE Submitting the Colors");
})
};

  if (!colorsAvailable) {
     return <div>Loading...</div>;
   }
  
 else{
   //console.log("PAssing these to circle VIew",circleColor,departmentColor,categoryColor,productColor); 
  // Define states for each tier with different default colors
  return (
    <div>
      <h2 className="welcomeText">Colors</h2>
      {/* Tier 1 (Location) */}
      <div style={{display:"flex"}}>
        <h2 style={{marginRight:"20px", width: "450px"}}>Tier 1 (Location)</h2>
        <div
          onClick={handleTier1ColorDivClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent:"stretch",
            flexWrap:"wrap"
          }}
        >
          <div
            style={{
              backgroundColor: tier1Color,
              width: '20px',
              height: '20px',
            }}
          ></div>
          <span style={{ marginLeft: '10px' }}>{tier1Color}</span>
        </div>
      </div>

      {/* Tier 2 */}
      <div style={{display:"flex"}}>
      <h2 style={{marginRight:"20px", width: "450px"}}>Tier 2 (Departments)</h2>
        <div
          onClick={handleTier2ColorDivClick}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: tier2Color,
              width: '20px',
              height: '20px',
            }}
          ></div>
          <span style={{ marginLeft: '10px' }}>{tier2Color}</span>
        </div>
      </div>

      {/* Tier 3 */}
      <div style={{display:"flex"}}>
      <h2 style={{marginRight:"20px", width: "450px"}}>Tier 3 (Product Categories)</h2>
        <div
          onClick={handleTier3ColorDivClick}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: tier3Color,
              width: '20px',
              height: '20px',
            }}
          ></div>
          <span style={{ marginLeft: '10px' }}>{tier3Color}</span>
        </div>
      </div>

      {/* Tier 4 */}
      <div style={{display:"flex"}}>
        <h2 style={{marginRight:"20px", width: "450px"}}>Tier 4 (Products)</h2>
        <div
          onClick={handleTier4ColorDivClick}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: tier4Color,
              width: '20px',
              height: '20px',
            }}
          ></div>
          <span style={{ marginLeft: '10px' }}>{tier4Color}</span>
        </div>
      </div>

<button className='add-button' onClick={submitColors}>Submit</button>

      {/* Display the color pickers for each tier */}
      {showTier1ColorPicker && (
        <div ref={tier1ColorPickerRef}>
          <h2>Tier 1 Color Picker:</h2>
          <ChromePicker
            color={tier1Color}
            onChangeComplete={handleTier1ColorChange}
          />
        </div>
      )}

      {showTier2ColorPicker && (
        <div ref={tier2ColorPickerRef}>
          <h2>Tier 2 Color Picker:</h2>
          <ChromePicker
            color={tier2Color}
            onChangeComplete={handleTier2ColorChange}
          />
        </div>
      )}

      {showTier3ColorPicker && (
        <div ref={tier3ColorPickerRef}>
          <h2>Tier 3 Color Picker:</h2>
          <ChromePicker
            color={tier3Color}
            onChangeComplete={handleTier3ColorChange}
          />
        </div>
      )}

      {showTier4ColorPicker && (
        <div ref={tier4ColorPickerRef}>
          <h2>Tier 4 Color Picker:</h2>
          <ChromePicker
            color={tier4Color}
            onChangeComplete={handleTier4ColorChange}
          />
        </div>
      )}
    </div>
  );
      }
};

export default Colors;
