import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";

import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";
import { fetchUsers } from "../FetchData/FetchUsers";
import { DeleteUser } from "../DeleteFromDB/DeleteUser";
import { EditUserToDB } from "../EditToDB/EditUserToDB";
import AddUsers from "../AddPages/AddUsers";
import EditUserModal from "../EditPages/EditUserModal";
import ViewPermissions from "../ViewPermissions";
import "../globalstyle.css";

const Users = () => {
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [users, setUsers] = useState([]);
  const data = { nodes: users };
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [userChangeFlag, setUserChangeFlag] = useState(true);
  const [viewPermissionsData, setViewPermissionsData] = useState(null);
  const [showViewPermissionsModal, setShowViewPermissionsModal] = useState(false);

  const toggleAddUserForm = () => {
    setShowAddUserForm(!showAddUserForm);
  };
  function onSortChange(action, state) {
    //console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
          background-color: #F2F2F2;
        `,
    Row: `
          &:nth-of-type(odd) {
            background-color: #FFFFF;
          }
  
          &:nth-of-type(even) {
            background-color: #F2F2F2;
          }
        `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        DEPT_ID: (array) =>
          array.sort((a, b) => {
            const idA = a.id.toString();
            const idB = b.id.toString();
            return idA.localeCompare(idB);
          }),
        DEPT_NAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        DEPT_DESC: (array) =>
          array.sort((a, b) => {
            const descriptionA = (a.description || "").toString();
            const descriptionB = (b.description || "").toString();
            return descriptionA.localeCompare(descriptionB);
          }),
        DEPT_DATE: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
        DEPT_LOC_NAME: (array) =>
          array.sort((a, b) => a.location_id.localeCompare(b.location_id)),
        DEPT_LOC_ID: (array) =>
          array.sort((a, b) => a.location_id - b.location_id),
      },
    }
  );

  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };

  useEffect(() => {
    async function fetchUsersData() {
      try {
        await fetchUsers().then((users)=>{
          setUsers(users);
         // console.log(users);
          
        });
     
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsersData();
    // eslint-disable-next-line
  }, [userChangeFlag]);

  const confirmDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this User ?")) {
      handleDelete(id);
    }
  };
  const handleDelete = async (id) => {
    try {
      await DeleteUser(id).then(() => {
        setUsers(users.filter((user) => user.id !== id));
      });
    } catch (error) {
      alert("Something Went Wrong in Delete ");
      console.error(`Error deleting User with ID ${id}:`, error);
    }
  };
  function handleEdit(userData) {
    setEditUserData(userData);
    setShowEditModal(true);
  }
  const closeEditModal = () => {
    setShowEditModal(false);
    setEditUserData({});
  };
  const handleSaveEdit = async (updatedData) => {
    try {
      //console.log(updatedData);
      if (!updatedData.password) {
        delete updatedData.password;
      }
     // console.log(updatedData);

      await EditUserToDB(updatedData).then(()=>{
        setUserChangeFlag(!userChangeFlag);
      });
    } catch (error) {
      console.error(
        `Error updating User with ID ${updatedData.id}:`,
        error
      );
      alert("Something Went Wrong in Edit ");
    }
  };
function handleViewPermissionsClick(item){
  //console.log(item);
  setViewPermissionsData(item); // Set the user data for the modal
  setShowViewPermissionsModal(true); // Open the modal

}
  return (
    <div>
    {showAddUserForm ? (
      <AddUsers
        userChangeFlag={userChangeFlag}
        setUserChangeFlag={setUserChangeFlag}
        toggleAddUserForm={toggleAddUserForm}
      />
    ) : (
      <>
        <div className="text-parent">
          <h2 className="welcomeText">Users</h2>
          <button className="add-button" onClick={toggleAddUserForm}>
            {" "}
            + Add New
          </button>
        </div>
        <div className="myTable" style={{ marginTop: "60px" }}>
          <Table data={data} sort={sort} theme={theme}>
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCellSort
                      resize={useResizeColumns}
                      sortKey="DEPT_LOC_NAME"
                    >
                       ID
                    </HeaderCellSort>
                    <HeaderCellSort
                      resize={useResizeColumns}
                      sortKey="DEPT_LOC_ID"
                    >
                       Name
                    </HeaderCellSort>
                    <HeaderCellSort
                      resize={useResizeColumns}
                      sortKey="DEPT_DESC"
                    >
                       Email
                    </HeaderCellSort>
                    <HeaderCellSort
                      resize={useResizeColumns}
                      sortKey="DEPT_NAME"
                    >
                      Permissions
                      </HeaderCellSort>
                    <HeaderCellSort
                      resize={useResizeColumns}
                      sortKey="DEPT_DATE"
                    >
                      Date Added
                    </HeaderCellSort>
                    <HeaderCellSort sortKey="STORE_NAME">Edit</HeaderCellSort>
                    <HeaderCellSort sortKey="STORE_NAME">
                      Delete
                    </HeaderCellSort>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item) => (
                    <Row item={item} key={item.id}>
                      <Cell>{item.id}</Cell>
                      <Cell>{item.name}</Cell>
                      <Cell>{item.email}</Cell>
    {(item.id === 1 || item.is_admin ===1) ? (
        <Cell>
        <div>ALL</div>
        </Cell>                    

    ) : (
        <Cell onClick={() => handleViewPermissionsClick(item)}>
        <a href="##" role="button">
          View
        </a>
      </Cell>
    )}
 <Cell>
                        {item.created_at
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}
                      </Cell>
                      <Cell>
    {item.is_admin === 1 ? (
        // Display a disabled Edit button for item.id === 1
        <IconButton
            color="primary"
            aria-label="Edit"
            disabled
        >
            <EditIcon />
        </IconButton>
    ) : (
        // Display an active Edit button for other item IDs
        <IconButton
            color="primary"
            aria-label="Edit"
            onClick={() => handleEdit(item)}
        >
            <EditIcon />
        </IconButton>
    )}
</Cell>
<Cell>

    {item.is_admin === 1  ? (
        // Display a disabled Delete button for item.id === 1
        <IconButton
            color="secondary"
            aria-label="Delete"
            disabled
        >
            <DeleteIcon />
        </IconButton>
    ) : (
        // Display an active Delete button for other item IDs
        <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => confirmDelete(item.id)}
        >
            <DeleteIcon />
        </IconButton>
    )}
</Cell>

                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </div>

        <EditUserModal
          isOpen={showEditModal}
          onRequestClose={closeEditModal}
          UserData={editUserData}
          onSave={handleSaveEdit}
        />

{viewPermissionsData && (
            <ViewPermissions
            isOpen={showViewPermissionsModal} // Control modal visibility
            onRequestClose={() => {
              setShowViewPermissionsModal(false); // Close the modal
              setViewPermissionsData(null); // Clear user data
            }}
              userData={viewPermissionsData} // Pass user data to the modal
            />
          )}
      </>
    )}
  </div>
  );
};

export default Users;
